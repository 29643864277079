
import { Component, Vue, Watch } from "vue-property-decorator";

import {
  Cell,
  CellGroup,
  Divider,
  List,
  NavBar,
  Empty,
  Search,
  Button,
  Sticky,
  Popup,
  Icon,
  PullRefresh,
} from "vant";
import { fectHotelOrder } from "@/api/hotel/index.api";
import QueryFilter from "./queryFilter.vue";

Component.registerHooks(["beforeRouteLeave", "beforeRouteEnter"]);
@Component({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Empty.name]: Empty,
    [Search.name]: Search,
    [Button.name]: Button,
    [Sticky.name]: Sticky,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    QueryFilter,
  },
})
export default class order extends Vue {
  public isShowPop: boolean = false;
  public getChangeDate(date) {
    return this.$moment(date).format("MM月DD日");
  }
  getSunDate(date1, date2) {
    let inDate = this.$moment(date1);
    let outDate = this.$moment(date2);
    return outDate.diff(inDate, "days");
  }
  getColor(stauts) {
    switch (stauts) {
      case "退订失败":
        return "red";
      case "已取消":
        return "red";
      case "已满房":
        return "red";
      case "已退款":
        return "red";
      default:
        return "#68ad47";
    }
  }
  public loading: boolean = false;
  public finished: boolean = false;
  public refreshing: boolean = false;
  public query = {
    Action: "QueryOrdersTo_C",
    NumOrPas: "", // 单号、出行人姓名
    OrderType: "All",
    BookingFromDate: "",
    BookingToDate: "",
    PageIndex: 0,
    PageSize: 30,
  };
  public hotelOrderList = [];

  onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.query.PageIndex = 0;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    if (this.refreshing) {
      this.hotelOrderList = [];
      this.refreshing = false;
    }
    this.query.PageIndex++;
    this.getOrderList();
  }
  async getOrderList() {
    try {
      this.$toast.loading({
        message: "搜索中...",
        loadingType: "spinner",
        duration: 0,
        className: "zIndex9999",
      });
      let {
        data: { ReturnJson },
      } = await fectHotelOrder(this.query);
      let orderData = JSON.parse(ReturnJson);
      if (!orderData) return (this.finished = true);

      if (orderData.TotalPage <= 1) {
        this.$set(this, "hotelOrderList", orderData.ResponseResult);
      } else {
        this.$set(
          this,
          "hotelOrderList",
          this.hotelOrderList.concat(orderData.ResponseResult)
        );
      }

      if (orderData.TotalPage <= orderData.PageNumber) {
        this.finished = true;
      }

      console.log("hotelOrderList", this.hotelOrderList);
    } finally {
      this.$toast.clear();
      this.loading = false;
    }
  }
  handleQueryData(val) {
    this.query = Object.assign({}, this.query, val);
    this.getOrderList();
  }
  // handleClear() {
  //   this.query = {
  //     Action: "QueryOrders",
  //     BookingFromDate: this.$moment()
  //       .subtract(12, "months")
  //       .format("YYYY-MM-01"),
  //     BookingToDate: this.$moment().format("YYYY-MM-DD"),
  //     PageIndex: 0,
  //     PageSize: 10,
  //   };
  //   this.getOrderList();
  // }

  public isShowPopup: boolean = false;
  public orderStatusList = [
    { title: "全部", status: "All" },
    { title: "待入住", status: "CanTravel" },
    { title: "待支付", status: "CanPay" },
    { title: "已入住", status: "Traveled" },
    { title: "退款/售后", status: "Return" },
  ];
  public getOrderStatus(status) {
    this.query.OrderType = status;
    this.getOrderList();
  }
  public keyWord = "";
  public searchKeyCord() {
    this.query.NumOrPas = this.keyWord;
    this.isShowPopup = false;
    this.getOrderList();
  }

  get getYear() {
    let nowYear = this.$moment().format("YYYY");
    let yearLsit = [];
    for (let i = 0; i < 5; i++) {
      yearLsit.unshift(nowYear - i);
    }
    yearLsit = yearLsit.map((item) => {
      let yearDate = item % 4 == 0 ? "31" : "30";
      return {
        title: item + "年",
        srartDate: item + "-" + "01" + "-" + "01",
        endDate: item + "-" + "12" + "-" + yearDate,
      };
    });
    return yearLsit;
  }
  public yearTitle = "";
  public handleGetYear(item) {
    this.yearTitle = item.title;
    this.query.BookingFromDate = item.srartDate;
    this.query.BookingToDate = item.endDate;
  }
  public clearYear() {
    this.yearTitle = "";
    this.query.BookingFromDate = "";
    this.query.BookingToDate = "";
  }
  @Watch("$route.query", { immediate: true, deep: false }) watchRouterData(
    newVal
  ) {
    if (!newVal.status) return;
    this.query.OrderType = newVal.status;
    this.getOrderList();
  }
  created() {
    // this.getOrderList();
  }

  // beforeRouteLeave(to, from, next) {
  //   let orderListScrolltop;
  //   if (to.name == "orderDetail") {
  //     orderListScrolltop = document.getElementById("order_list").scrollTop;
  //   } else orderListScrolltop = 0;
  //   sessionStorage.setItem("orderListScrolltop", orderListScrolltop + "");
  //   next();
  // }
  // activated() {
  //   const scrollTop = +sessionStorage.getItem("orderListScrolltop");
  //   const $content = document.getElementById("order_list");
  //   if (scrollTop && $content) {
  //     $content.scrollTop = scrollTop;
  //   }
  //   this.getOrderList();
  // }
}
