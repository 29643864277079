
class paramVo {
  OrderNum?: string | number;
  BookingFromDate?: string;
  BookingToDate?: string;
  CheckInDate?: string;
  CheckOutDate?: string;
  PassengerName?: string;
  Status?: [string];
  CustomerSource?: string;
  SerialNo?: string;
}
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Cell,
  CellGroup,
  Popup,
  Field,
  NavBar,
  DatetimePicker,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Button
} from 'vant';
import { fectHotelOrder, fectHotelDetailApi } from '@/api/hotel/index.api';
@Component({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [DatetimePicker.name]: DatetimePicker,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button
  }
})
export default class QueryFilter extends Vue {
  @Prop({ type: Boolean, default: false }) isShowPop;
  @Prop({ type: Object, default: () => ({}) }) queryObj;
  public show: boolean = true;
  @Watch('isShowPop') watchPop(newVal) {
    this.show = newVal;
  }
  @Watch('queryObj', { deep: true, immediate: true }) watchQueryObj(newVal) {
    this.query = Object.assign({}, this.query, newVal);
  }
  public back() {
    this.$emit('update:isShowPop', false);
  }
  getDate(date) {
    return this.$moment(date).format('YYYY-MM-DD');
  }

  // public Protocols = [];
  // async getProtocols() {
  //   let {
  //     data: { ReturnJson }
  //   } = await fectHotelDetailApi({
  //     Action: 'QueryCustomerSource'
  //   });
  //   this.Protocols = JSON.parse(ReturnJson);
  // }

  public query = new paramVo();

  // 订单状态
  public orderStatusList = [
    // { title: '询房中', val: 100 },
    { title: '待支付', val: 200 },
    { title: '订房中', val: [300, 350] },
    { title: '已成交', val: 400 },
    { title: '已满房', val: 360 },
    { title: '已取消', val: 160 },
    { title: '退订中', val: [500, 600, 650] },
    { title: '退订失败', val: 660 },
    { title: '已退款', val: 700 }
  ];

  public showFromPicker: boolean = false;
  public showToPicker: boolean = false;
  public showInPicker: boolean = false;
  public showOutPicker: boolean = false;

  async handeleQuery() {
    this.$emit('queryData', this.query);
    this.back();
  }
  clear() {
    this.query = new paramVo();
    this.$emit('clear');
  }
  created() {
    // this.getProtocols();
  }
}
